import React, { useState, useEffect, useMemo, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import videosImg from "../../../assets/course_content_tab/videos.png";
import materialsImg from "../../../assets/course_content_tab/materials.jpg";
import quizsImg from "../../../assets/course_content_tab/quizzes.jpg";
import assignmentsImg from "../../../assets/course_content_tab/Assignments.jpg";

import { DarkModeContext } from "../../../Context/DarkModeContext.jsx";

function CourseDetailPage() {
  const { isDarkMode } = useContext(DarkModeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const course = state?.course;

  // Cards data
  const cards = [
    { title: "Videos", image: videosImg, route: "videos" },
    { title: "Materials", image: materialsImg, route: "materials" },
    { title: "Quizzes", image: quizsImg, route: "quizzes" },
    { title: "Assignments", image: assignmentsImg, route: "assignments" },
  ];

  // Get current page and section from URL
  // Memoize the queryParams object
  const queryParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params;
  }, [location.search]);
  const section = queryParams.get("section") || "";
  const initialPage = parseInt(queryParams.get("page")) || 1;

  // Pagination state
  const [currentPage, setCurrentPage] = useState(initialPage);
  const cardsPerPage = 4; // Number of cards per page

  // Effect to scroll to top on page change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // Effect to update currentPage based on URL query parameters
  useEffect(() => {
    const page = parseInt(queryParams.get("page")) || 1;
    setCurrentPage(page);
  }, [queryParams]);

  // Calculate pagination
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = cards.slice(indexOfFirstCard, indexOfLastCard);

  // Handlers for pagination
  const goToNextPage = () => {
    navigate(+1);
  };

  const goToPreviousPage = () => {
    navigate(-1);
  };

  // Handler for card click
  const handleCardClick = (route, course) => {
    if (course) {
      const courseName = course.name
        .replace(/\s+/g, "_") // Replace spaces with underscores
        .replace(/\//g, "-") // Replace slashes with hyphens
        .toLowerCase();

      const safeCourseName = encodeURIComponent(courseName);
      navigate(
        `/courses/${safeCourseName
          .replace(/\s+/g, "_")
          .toLowerCase()}/${route}`,
        { state: { course } }
      );
    }
  };

  // Format path to include the section name
  const formattedPath = `${location.pathname
    .slice(1) // Remove leading slash
    .replace(/[_-]/g, " ") // Replace underscores or hyphens with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize first letter of each word
    .replace(/\//g, " > ")}
  > ${section.charAt(0).toUpperCase() + section.slice(1)}`; // Append the current section

  return (
    <div
      className={`flex-1 p-8 min-h-screen ${
        isDarkMode ? "bg-[#121212] text-white" : "bg-customBlue text-black"
      }`}
    >
      <div
        className={`${
          isDarkMode ? "bg-[#1e1e1e]" : "bg-white"
        } text-black p-8 mt-1 rounded-lg shadow-lg`}
      >
        {/* PATH AND CONTROLS */}
        <div className="lg:flex w-full items-center mb-4 text-gray-700 text-sm capitalize">
          <div className="flex w-[58px] gap-1">
            <button
              onClick={goToPreviousPage}
              className="w-[24px] h-[20px] flex justify-center items-center bg-gray-300 rounded-[6px] disabled:opacity-50"
            >
              <IoIosArrowBack />
            </button>
            <button
              onClick={goToNextPage}
              className="w-[24px] h-[20px] flex justify-center items-center bg-gray-300 rounded-[6px] disabled:opacity-50"
            >
              <IoIosArrowForward />
            </button>
          </div>

          {/* Display current path */}
          <div
            className={`${
              isDarkMode ? "text-white" : "text-black"
            } flex items-center text-sm capitalize p-2`}
          >
            <span>{formattedPath}</span>
          </div>
        </div>

        {/* Cards */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {currentCards.map((card, index) => (
            <div
              key={index}
              className="relative bg-white rounded-[10px] shadow-md overflow-hidden flex justify-center items-center cursor-pointer hover:scale-customScale ease-in-out duration-300"
              onClick={() => handleCardClick(card.route, course)}
            >
              <img
                src={card.image}
                alt={card.title}
                className={`w-full h-64 object-cover ${
                  isDarkMode ? "opacity-100" : "opacity-80"
                }`}
              />
              <div className="absolute inset-0 flex justify-center items-center">
                <h3 className="text-white lg:text-[50px] md:text-[30px] text-xl font-bold">
                  {card.title}
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CourseDetailPage;
